.gridRow {
  fill: #fff;
}

.gridRow:nth-child(even) {
  /* fill: #f5f5f5; */
  fill: #fff;
}

.gridRowLine {
  /* stroke: #ebeff2; */
}

.gridTick {
  stroke: #e6e4e4;
}
