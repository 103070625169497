.barLabel {
  fill: #333;
  text-anchor: middle;
  font-weight: normal;
  dominant-baseline: central;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  text-overflow: ellipsis;
}

.barLabelOutside {
  fill: #000;
  text-anchor: middle;
  dominant-baseline: central;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}
